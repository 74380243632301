import { React, useState, useEffect } from "react";
import "./Portfolio_item.css";
import sanityClient from "../../client";

function Portoflio_item() {
  const [objects, setObjects] = useState(null);

  const url = window.location.href;

  const lastSegment = url.split("/").pop();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "portfolioPost" && _id == "${lastSegment}"]{
        title,
        mainImage{
            asset->{url},
        },
        images[]{
            asset->{url}
        }
        }`,
      )
      .then((data) => {
        setObjects(data);
      })
      .catch(console.error);
  }, []);
  return (
    <div className="item-background">
      <div className="portfolio-item">
        <h1 className="h1-title portfolio-item-title">
          {objects ? objects[0]["title"] : ""}
        </h1>
        <div className="portfolio-content">
          {objects ? (
            objects[0]["images"].map((e, i) => {
              console.log(e.asset.url);
              return (
                <img
                  src={e.asset.url}
                  alt="error"
                  className="portfolio-image"
                  key={i}
                />
              );
            })
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Portoflio_item;
