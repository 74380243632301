import { React, useState, useEffect } from "react";
import "./News_item.css";
import sanityClient from "../../client";

function News_item() {
  const [objects, setObjects] = useState(null);

  const url = window.location.href;

  const lastSegment = url.split("/").pop();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "newsPost" && _id == "${lastSegment}"]{
            title,
            date,
            location,
            content,
            mainImage{
                asset->{url},
            },
            images[]{
              asset->{url}
          }            
        }`,
      )
      .then((data) => {
        setObjects(data);
      })
      .catch(console.error);
  }, []);
  return (
    <div className="news-background">
      <div className="news-item-page">
        <div className="news-item">
          <div className="left-column-news">
            <h1 className="h1-title news-title">
              {objects ? objects[0]["title"] : ""}
            </h1>
            <p className="content">Data: {objects ? objects[0]["date"] : ""}</p>
            <p className="content">
              Localização: {objects ? objects[0]["location"] : ""}
            </p>
            <p className="content">{objects ? objects[0]["content"] : ""}</p>
          </div>
          <img
            className="news-image"
            src={objects ? objects[0]["mainImage"]["asset"]["url"] : ""}
          ></img>
        </div>
        <div className="portfolio-content">
          {objects ? (
            objects[0]["images"].map((e, i) => {
              return (
                <img
                  src={e.asset.url}
                  alt="error"
                  className="portfolio-image"
                  key={i}
                />
              );
            })
          ) : (
            <span></span>
          )}
        </div>
      </div>
    </div>
  );
}

export default News_item;
