import React from "react";
import './Contact_container.css'

export const Contact_container = (
    {children,
    url,
    title
    }) => 
    {
        return(
            <div className="contact-info-container">
                <div className="icon-title-container">
                    <img src={url} alt="" />
                    <h4 className="contact-info-title">{title}</h4>
                </div>
                {children}
            </div>
        );
};
// good luck because this is a mess...