import React, { useState, useEffect } from "react";
import { Link, animateScroll, scroller } from "react-scroll";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [background, setBackground] = useState(0);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setBackground(1);
    } else {
      setBackground(0);
    }
  };

  window.addEventListener("scroll", handleScroll);
  const navigate = useNavigate();

  return (
    <>
      <nav className={background === 0 ? "navbar" : "navbar--second"}>
        <div className='navbar-container'>
          <RouterLink to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img
              src={
                process.env.PUBLIC_URL +
                (background === 0 ? "/logo_white.svg" : "/logo_black.svg")
              }
            />
          </RouterLink>
          <div className='menu-icon' onClick={handleClick}>
            <i
              className={click ? "fas fa-times" : "fas fa-bars"}
              id={background === 0 ? "w" : "b"}
            />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className='nav-item'>
              <Link
                to='empresa-about-section'
                className='nav-links'
                onClick={() => {
                  closeMobileMenu();
                  if (window.location.href != "http://coplusa.pt/") {
                    navigate("/");
                  }
                }}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                activeClass='active'
              >
                Empresa
              </Link>
            </li>
            <li className='nav-item'>
              <RouterLink
                to='/services'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Serviços
              </RouterLink>
            </li>
            <li className='nav-item'>
              <RouterLink
                to='/portfolio'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Portfólio
              </RouterLink>
            </li>
            <li className='nav-item'>
              <RouterLink
                to='/news'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Notícias
              </RouterLink>
            </li>
            <li className='nav-item'>
              <RouterLink
                to='/contact'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Contactos
              </RouterLink>
            </li>
            <li className='nav-item'>
              <div className='social-wrapper'>
                <a
                  className='icon-wrapper'
                  href='https://www.facebook.com/coplusa.lda'
                >
                  <img
                    className='social-icon'
                    src={
                      process.env.PUBLIC_URL +
                      (background != 0 && !click
                        ? "/icons/fb.png"
                        : "/icons/fb_w.png")
                    }
                    alt=''
                  />
                </a>
                <a
                  className='icon-wrapper'
                  href='https://www.instagram.com/coplusa_lda'
                >
                  <img
                    className='social-icon'
                    src={
                      process.env.PUBLIC_URL +
                      (background != 0 && !click
                        ? "/icons/in.png"
                        : "/icons/in_w.png")
                    }
                    alt=''
                  />
                </a>
                <a
                  className='icon-wrapper'
                  href='https://www.linkedin.com/company/coplusa-lda/mycompany'
                >
                  <img
                    className='social-icon'
                    src={
                      process.env.PUBLIC_URL +
                      (background != 0 && !click
                        ? "/icons/lk.png"
                        : "/icons/lk_w.png")
                    }
                    alt=''
                  />
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
