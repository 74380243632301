import React from "react";
import "./Footer.css";
import { Button } from "../Button/Button.js";
import { Link as RouterLink } from "react-router-dom";

function Footer() {
  return (
    <div className='footer'>
      <div className='logo-container'>
        <img src={process.env.PUBLIC_URL + "/logo_white.svg"} />
      </div>
      <div className='social-row'>
        <hr />
        <a className='icon-wrapper' href='https://www.facebook.com/coplusa.lda'>
          <img
            className='social-icon'
            src={process.env.PUBLIC_URL + "/icons/fb_w.png"}
            alt=''
          />
        </a>
        <a
          className='icon-wrapper'
          href='https://www.instagram.com/coplusa_lda'
        >
          <img
            className='social-icon'
            src={process.env.PUBLIC_URL + "/icons/in_w.png"}
            alt=''
          />
        </a>
        <a
          className='icon-wrapper'
          href='https://www.linkedin.com/company/coplusa-lda/mycompany'
        >
          <img
            className='social-icon'
            src={process.env.PUBLIC_URL + "/icons/lk_w.png"}
            alt=''
          />
        </a>
        <hr />
      </div>
      <div className='message-button'>
        <Button buttonSize='btn--small' buttonStyle='btn--outline'>
          <RouterLink to='/contact'>Enviar Mensagem</RouterLink>
        </Button>
      </div>
      <div className='legal-container'>
        <p>
          © 2022 Todos os direitos reservados | Coplusa - Construção de Obras
          Públicas e Privadas Lda.
        </p>
        <a href='https://www.termsfeed.com/live/3d2dc352-c2f9-481f-9a9e-023f3e4a2950'>
          Política de privacidade
        </a>
        <a href="https://www.livroreclamacoes.pt/Inicio/">
          Livro de Reclamações
        </a>
      </div>
    </div>
  );
}

export default Footer;
