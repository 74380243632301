import React from "react";
import Navbar from "./components/Navbar/Navbar";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Services from "./pages/services/Services";
import Starting from "./pages/startingpage/Starting";
import Contact from "./pages/contact/Contact";
import Portfolio from "./pages/portfolio/Portfolio";
import Footer from "./components/Footer/Footer";
import Noticias from "./pages/noticias/Noticias";
import Portoflio_item from "./pages/portfolio_item/Portoflio_item";
import News_item from "./pages/news_item/News_item";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <title>Coplusa</title>
        <meta
          name='description'
          content='Coplusa | Confiança é só uma das coisas
que nós construímos'
        />
        <meta name='theme-color' content='#333333' />
      </Helmet>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path='/' element={<Starting />} />
          <Route path='/services' element={<Services />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/news' element={<Noticias />} />
          <Route path='/portfolio/:name' element={<Portoflio_item />} />
          <Route path='/news/:name' element={<News_item />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
