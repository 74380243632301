import { React, useEffect } from "react";
import "./Services.css";
import { Service } from "../../components/Service/Service";

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='services'>
      <Service
        title='Projeto'
        _url={process.env.PUBLIC_URL + "/images/projeto.jpg"}
        color='--grey'
      >
        Possuímos um departamento dedicado ao desenvolvimento de projetos, com
        um conceito uniforme e personalizado, desenhado à medida das
        necessidades e gostos de cada cliente. Realizamos projetos de
        Arquitetura, de interiores, de design, de 3D e de Engenharia.
      </Service>
      <Service
        title='Construção'
        _url={process.env.PUBLIC_URL + "/images/12.jpg"}
        invert={true}
      >
        A nossa empresa garante um produto final seguro, rápido e económico,
        respeitando os objectivos do cliente. Temos um conjunto de equipas
        múltiplas de técnicos profissionais especializados e preparados para
        intervir em todas as fases da construção.
      </Service>
      <Service
        title='Reabilitação'
        _url={process.env.PUBLIC_URL + "/images/reabilitacao.jpg"}
        color='--grey'
      >
        Realizamos pinturas, impermeabilizações e revestimentos em diversos
        materiais. Os nossos projectos de restauro de edifícios são elaborados
        tendo em vista a reabilitação total das fachadas exteriores. Realizamos
        também o restauro de terraços e das coberturas dos prédios.
      </Service>
      <Service
        title='Remodelação'
        _url={process.env.PUBLIC_URL + "/images/remodelacao_coplusa.jpg"}
        invert={true}
      >
        Os trabalhos de remodelação levados a cabo pelas nossas equipas de
        profissionais, são sempre executadas com recurso aos melhores e mais
        recentes materiais e técnicas, de forma a garantir a máxima qualidade
        dos serviços prestados.
      </Service>
      <Service
        title='Carpintaria'
        _url={process.env.PUBLIC_URL + "/images/carpintaria.jpg"}
        color='--grey'
        smaller={true}
      >
        Trabalhamos diretamente com uma empresa de referência em serviços de
        Carpintaria e acabamentos para a Construção Civil, a{" "}
        {<a href='https://newtimbers.com/'>Equação Ponderada</a>}. Esta consiste
        numa empresa com um serviço de excelência e totalmente personalizável,
        indo ao encontro das necessidades de todos os nossos clientes, mais e
        menos exigentes. A Equação Ponderada dispõe de instalações, equipamentos
        e recursos humanos que permitem produzir a generalidade de peças de
        mobiliário e outros produtos como portas, janelas, caixilhos, rodapés,
        soalhos, tetos, escadas, revestimentos interiores e exteriores,
        coberturas e peças decorativas. A vasta experiência na área da
        Construção Civil permite ainda desenvolver e executar soluções
        inovadoras autonomamente, desde a tradicional Carpintaria em madeiras
        maciças até à incorporação dos mais recentes materiais e técnicas
        atuais.
      </Service>
    </div>
  );
}

export default Services;
