import React from "react";
import "./EImages.css";

export default function EImages() {
  return (
    <div className='wrapper'>
      <img id='left' src={process.env.PUBLIC_URL + "images/10.jpg"} alt='' />
      <img id='right' src={process.env.PUBLIC_URL + "images/06.jpg"} alt='' />
    </div>
  );
}
