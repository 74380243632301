import { React, useEffect, useState } from "react";
import { Portfolio_component } from "../../components/Portfolio_component/Portfolio_component";
import "./Portfolio.css";
import sanityClient from "../../client";

function Portfolio() {
  const [objects, setObjects] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    sanityClient
      .fetch(
        `*[_type == "portfolioPost"] | order(_createdAt desc){
           _id,
         title,
         mainImage{
             asset->{url},
         }
       }`,
      )
      .then((data) => {
        setObjects(data);
        console.log(data);
      })
      .catch(console.error);
  }, []);
  return (
    <div className="portfolio">
      <div className="portfolio-hero"></div>
      <div className="portfolio-content">
        <h1 className="h1-title">Portfólio</h1>
        {objects ? (
          objects.map((e, i) => {
            return (
              <Portfolio_component
                url={e.mainImage.asset.url}
                key={i}
                index={e._id}
              >
                {e.title}
              </Portfolio_component>
            );
          })
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
}

export default Portfolio;
