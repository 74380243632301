import React from 'react';
import { Button } from '../Button/Button';
import './HeroSection.css';
import {scroll} from 'react-scroll';

function HeroSection() {
  return (
    <div className='hero-container'>
        <div className="hero-wrapper">
            <div className="hero-content-wrapper">
                <h1 className="hero-title">Confiança é só uma das coisas</h1>
                <h1 className="hero-title --b">que nós construímos</h1>
                <Button>Saber Mais</Button>
            </div>
        </div>
    </div>
  );
}

export default HeroSection;