import { React, useEffect, useState } from "react";
import { Button } from "../../components/Button/Button";
import { Contact_container } from "../../components/Contact/Contact_container";
import "./Contact.css";
import emailjs from "emailjs-com";

function Contact() {
  const [alertClass, setalertClass] = useState("alert--hide");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const submitForm = (e) => {
    e.preventDefault();
    if (
      e.target.email.value &&
      e.target.mensagem.value &&
      e.target.name.value
    ) {
      emailjs
        .sendForm(
          "service_df1q85o",
          "template_9f2qzha",
          e.target,
          "ALIMOD1bnpzUdxQBQ"
        )
        .then(
          (result) => {
            console.log(result.text);
            console.log(e.target.email.value);
            setalertClass("alert");
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };
  return (
    <div className='contact'>
      <div className='contact-hero'></div>
      <div className='contact-pageContent'>
        <div className='contactForm-container'>
          <h1 className='h1-title'>Contacte-nos</h1>
          <form onSubmit={submitForm}>
            <p className='field-req'>
              *Todos os campos são de preenchimento obrigatório
            </p>
            <input type='text' name='name' placeholder='Nome' />
            <input type='text' name='email' placeholder='E-mail' />
            <textarea name='mensagem' placeholder='Mensagem' />
            <Button buttonStyle='btn--outline' type='submit'>
              Enviar
            </Button>
          </form>
        </div>
        <div className='contact-container'>
          <Contact_container
            title='E-mail'
            url={process.env.PUBLIC_URL + "icons/at.png"}
          >
            <p>coplusa@coplusa.pt</p>
            <p>comercial@coplusa.pt</p>
          </Contact_container>
          <Contact_container
            title='Telefone'
            url={process.env.PUBLIC_URL + "icons/tlf.png"}
          >
            <p>+351 232 852 731</p>
            <p style={{fontSize: '12px'}}>(Chamada para rede fixa nacional)</p>
          </Contact_container>
          <Contact_container
            title='Telemóvel'
            url={process.env.PUBLIC_URL + "icons/tlm.png"}
          >
            <p>+351 966 796 966</p>
            <p>+351 917 840 599</p>
            <p style={{fontSize: '12px'}}>(Chamada para rede móvel nacional)</p>
          </Contact_container>
          <Contact_container
            title='Localização'
            url={process.env.PUBLIC_URL + "icons/plc.png"}
          >
            <p>Avenida Dr. João Almiro, Nº 1670 - </p>
            <p>Edif.Galena - Loja 3</p>
            <p>3465-051 Campo de Besteiros - Tondela</p>
          </Contact_container>
          <Contact_container
            title='Horário'
            url={process.env.PUBLIC_URL + "icons/sch.png"}
          >
            <p>Segunda - Sexta</p>
            <p>09:00 - 18:00</p>
          </Contact_container>
        </div>
      </div>
      <div class={alertClass}>
        <span
          class='closebtn'
          onClick={() => {
            console.log("changing class");
            setalertClass("alert--hide");
          }}
        >
          &times;
        </span>
        Formulário enviado
      </div>
    </div>
  );
}

export default Contact;
