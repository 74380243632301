import './Service.css';
import React from 'react';

export const Service = ({children, 
    _url, 
    title, 
    color,
    invert,
    smaller,
}) => {
    color = color != null ? color : '--white';
    const text_size = smaller ? "20px" : "24px";
    const text_color = color === '--white' ? '#000' : '#fff';
    return(
        <div className='service-container' style={{background: `var(${color})`}}>
            <div className={invert ? "service-wrapper--invert":"service-wrapper"}>
                <img className={invert ? "service-image--invert":"service-image"} src={_url}></img>
                <div className={invert ? "service-text-wrapper--invert":"service-text-wrapper"} style={{color: `${text_color}`}}>
                    <h1 className='h1-title align-left no-margin-bottom'>{title}</h1>
                    <p style={{fontSize: `${text_size}`, lineHeight: '1.5'}}>{children}</p>
                </div>
            </div>
        </div>
    );
};