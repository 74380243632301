import React from "react"
import './News_component.css'
import { Link as RouterLink} from 'react-router-dom';

export const News_component = ({title,children, url, index}) => {
    return(
        <RouterLink className="news-button" to={`/news/${index}`}>
            <div className="left-column-news">
                <h1 className="h1-title news-title">{title}</h1>
                <p className="description">{children}</p> 
            </div>
            <img className="news-image" src={url}></img>                     
        </RouterLink>
    );
}