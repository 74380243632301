import {React, useEffect} from 'react'
import HeroSection from '../../components/HeroSection/HeroSection'
import Empresa from '../empresa/Empresa'

function Starting() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
        <HeroSection/>
        <Empresa/>
    </>
  )
}

export default Starting
