import React from "react"
import './Portfolio_component.css'
import { Link as RouterLink} from 'react-router-dom';

export const Portfolio_component = ({children, url, index}) => {
    return(
        <RouterLink className="portfolio-button" to={`/portfolio/${index}`}>
            <h1 className="h1-title">{children}</h1>
            <img className="portfolio-component" src={url}></img>                       
        </RouterLink>

    );
}