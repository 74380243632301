import React from "react";
import "./MV_section.css";

export default function MV_section() {
  return (
    <div className='mv-section'>
      <div className='mission'>
        <div className='mission-wrapper'>
          <div className='mission-left'>
            <h1 className='h1-title align-left'>Missão</h1>
            <p className='left-content'>
              A Qualidade, Confiança, Solidez e Segurança são os nossos
              alicerces, estabelecendo sólidas relações de parceria com os
              nossos clientes, fornecedores e colaboradores, honrando os nossos
              compromissos. Construir, apostando na Inovação, formação interna e
              desenvolvimento organizacional melhorando continuamente a nossa
              competitividade, focados na satisfação e fidelização dos nossos
              clientes.
            </p>
          </div>
          <img src={process.env.PUBLIC_URL + "images/15.jpg"} alt='' />
        </div>
      </div>
      <div className='vision'>
        <div className='vision-wrapper'>
          <h1 className='h1-title align-left'>Visão</h1>
          <p className='vision-content'>
            A visão da Coplusa define-se como a perspectiva de se tornar numa
            empresa de relevo na sua área de negócio, procurando, essencialmente
            rentabilizar os investimentos e promover um local de trabalho que
            inspire respeito pelos seus colaboradores proporcionando uma
            sensação de segurança e bem-estar.
          </p>
          <p className='vision-content'>
            Junto dos seus clientes apresentar-se como uma das melhores
            escolhas, especialista em resolver todo e qualquer problema,
            apresentando sempre as melhores e mais vantajosas soluções.
          </p>
        </div>
      </div>
    </div>
  );
}
