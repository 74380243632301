import { React, useState, useEffect } from "react";
import { News_component } from "../../components/News_component/News_component";
import "./Noticias.css";
import sanityClient from "../../client";

function Noticias() {
  const [objects, setObjects] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    sanityClient
      .fetch(
        `*[_type == "newsPost"] | order(_createdAt desc){
            _id,
         title,
         date,
         content,
         mainImage{
             asset->{url},
         }
       } `,
      )
      .then((data) => {
        // order by date
        setObjects(data);
      })
      .catch(console.error);
  }, []);

  return (
    <div className="noticias">
      <div className="noticias-hero"></div>
      <div className="noticias-content">
        <h1 className="h1-title">Notícias</h1>
        {objects ? (
          objects.map((e, i) => {
            return (
              <News_component
                url={e.mainImage.asset.url}
                key={i}
                index={e._id}
                title={e.title}
              >
                {e.content}
              </News_component>
            );
          })
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
}

export default Noticias;
